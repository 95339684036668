import * as React from 'react';

function MenuIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="square"
        d="M5.25 8.084h21.5M5.25 16.084h21.5M5.25 24.084h21.5"
      />
    </svg>
  );
}

export default MenuIconComponent;

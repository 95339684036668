import Link from 'next/link';
import { ButtonSmallText } from './Texts';
import { twMerge } from 'tailwind-merge';

export default function PrimaryCTAComponent({
  title,
  id,
  children,
  href,
  className,
}: {
  title: string;
  children?: React.ReactNode;
  href: string;
  id?: string;
  className?: string;
}) {
  return (
    <Link
      id={id}
      href={{
        pathname: href,
      }}
      className={twMerge(
        'flex shrink-0 items-center justify-between px-6 py-3',
        className,
      )}
    >
      <ButtonSmallText className="font-bold">{title}</ButtonSmallText>
      {children}
    </Link>
  );
}
